@import "src/global-styles/typography";
@import "src/global-styles/colors";

.action-alert {
    &__modal {
        min-width: 475px;
        max-width: 624px;
        flex-shrink: 0;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        color: #000;
    }

    &__wrapper{
        overflow: hidden;
        padding: 35px;
        display: block;
        background: linear-gradient(180deg, #FFF 0%, #F1EEEA 100%);

        .close-icon {
            float: inline-end;
        }
    }

    &__heading {
        font-size: 24px;
        font-style: normal;
        text-align: center;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: -0.48px;
        margin-bottom: 24px;
        // margin: 24px 24px 16px 24px;
    }

    &__details {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        margin-top: 8px;
        margin-bottom: 16px;

        .error-text {
            margin-right: 63px;
            margin-left: 63px;
            margin-top: 16px;
        }
    }

    &__action-container {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 24px;

        button {
            margin: auto 16px;

        }

        button.primary {
            padding: var(--Spacing-s, 8px) var(--Spacing-m, 16px);
            background: #197A56;
            height: 40px;
            text-transform: capitalize;
            min-width: 115px;
            border: 1px solid #197a56;
            color: #fff;
        }

        a {
            color: #197A56;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            margin-right: 32px;
        }
    }
}